import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router';
import { reactLocalStorage } from 'reactjs-localstorage';
import AdminDetail from 'pages/_lbv/admin/AdminDetail';
import Admins from 'pages/_lbv/admin/Admins';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - utilities
const Places = Loadable(lazy(() => import('pages/_lbv/places/Places')));
const AddPlaces = Loadable(lazy(() => import('pages/_lbv/places/AddPlaces')));
const Bookings = Loadable(lazy(() => import('pages/_lbv/booking/Booking')));
const BookingDetail = Loadable(lazy(() => import('pages/_lbv/booking/BookingDetail')));

const Region = Loadable(lazy(() => import('pages/_lbv/region/Regions')));
const Location = Loadable(lazy(() => import('pages/_lbv/region/Locations')));

const Coupons = Loadable(lazy(() => import('pages/_lbv/coupon/Coupons')))
const CouponDetail = Loadable(lazy(() => import('pages/_lbv/coupon/CouponDetail')))

// ==============================|| MAIN ROUTING ||============================== //

const isLoggedIn = reactLocalStorage.get('lbv_admin_token');

// load reactlocalstorage
let getUserInfo = reactLocalStorage.get('user_info')
getUserInfo = getUserInfo ? JSON.parse(getUserInfo) : null

let PlaceRoutes = {
  path: 'places',
  children: [
    {
      path: '',
      element: <Places />
    },
    {
      path: 'add-place',
      element: <AddPlaces />
    },
    {
      path: ':id',
      element: <AddPlaces />
    }
  ]
}

let SuperAdminSettings = {
  path: 'setting',
  children: [
    {
      path: 'region',
      element: <Region />
    },
    {
      path: 'location',
      element: <Location />
    },
    {
      path: 'admins',
      children: [
        {
          path: '',
          element: <Admins />
        },
        {
          path: 'add-admin',
          element: <AdminDetail />
        },
        {
          path: ':id',
          element: <CouponDetail />
        }
      ]
    },
  ]
}

let LBVRoutes = {
  path: '/',
  element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'default',
    //       element: <DashboardDefault />
    //     }
    //   ]
    // },
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'order',
      children: [
        {
          path: '',
          element: <Bookings />
        },
        {
          path: ':id',
          element: <BookingDetail />
        },
      ]
    },
    {
      path: 'coupons',
      children: [
        {
          path: '',
          element: <Coupons />
        },
        {
          path: 'add-coupon',
          element: <CouponDetail />
        },
        {
          path: ':id',
          element: <CouponDetail />
        }
      ]
    },
  ]
};

if (getUserInfo && (!getUserInfo.role || getUserInfo.role == "superadmin")) {
  LBVRoutes.children.unshift(PlaceRoutes)
  LBVRoutes.children.push(SuperAdminSettings)
}

export default LBVRoutes;
