import { Button, Card, Grid, Switch } from "@mui/material";
import { LBVInput, LBVSelect } from "components/_lbvcomponents/LBVInput";
import React, { useState } from "react";
import { createAdmin } from "services/adminService";

const adminRoleOptions = [
    { value: "superadmin", label: "superadmin"},
    { value: "finance", label: "finance"},
    { value: "admin", label: "admin"},
]

function AdminDetail () {

    const [ form, setForm ] = useState({
        name: "",
        username: "",
        role: { value: "superadmin", label: "superadmin"},
        password: "",
        isActive: true,
    })

    const onChange = (e) => {
        setForm((prev) => ({
            ...prev,
            ...e
        }))
    }

    const submitForm = async () => {
        let { data, error } = await createAdmin({
            ...form,
            role: form.role ? form.role.value : null
        })
        if (error) { alert(error) }
        if (data) {
            alert('Create new admin success')
            window.location.href = '/setting/admins'
        }
    }

    return (
        <Grid container xs={12} lg={4}>
            <Card>
                <Grid container xs={12} lg={12} p={2} spacing={1}>
                    <Grid item xs={12}>
                        <LBVInput label={"Name"} onChange={(e) => {
                            onChange({name: e.currentTarget.value})
                        }} value={form.name}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LBVInput label={"Username"} onChange={(e) => {
                            onChange({username: e.currentTarget.value})
                        }}  value={form.username}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LBVSelect label={"Role"} options={adminRoleOptions} onChange={(e) => {
                            onChange({role: e})
                        }}  value={form.role}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LBVInput label={"Password"} type={"password"} onChange={(e) => {
                            onChange({password: e.currentTarget.value})
                        }}  value={form.password}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Switch
                            // disabled={value.disabled || disabled}
                            checked={form.isActive} 
                            onChange={() => onChange({isActive: !form.isActive})}
                        />
                    </Grid>
                    <Grid item xs={12} mt={1}>
                        <Button variant="contained" onClick={() => {
                            submitForm()
                        }}>{form._id ? "Update" : "Create"}</Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

export default AdminDetail