import React, { useEffect, useState } from "react";
import { Button, Card, Dialog, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import LBVTable from "components/_lbvcomponents/LBVTable";
import { LBVLabel } from "components/_lbvcomponents/LBVLabel";
import { deleteAdmin, getAdminList } from "services/adminService";

function Admins () {
    const navigate = useNavigate()

    const [ admins, setAdmins ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ openDialog, setOpenDialog ] = useState({
        open: false
    })

    useEffect(() => {
        loadAdminList()
    }, [])

    const loadAdminList = async () => {
        let { data, error } = await getAdminList()
        if (error) { console.log(error) }
        if (data) { setAdmins(data) }
    }

    const removeAdmin = async (adminId) => {
        let { data, error } = await deleteAdmin({
            adminId
        })
        if (error) { console.log(error) }
        if (data) { 
            setOpenDialog({
                open: false
            })
            setAdmins(data) 
        }
    }

    const valueCustomator = (data) => {
        return ({
            name: <LBVLabel>{data.name || '-'}</LBVLabel>,
            username: <LBVLabel>{data.username}</LBVLabel>,
            role: <LBVLabel>{data.role}</LBVLabel>,
            action: <Grid container textAlign={"right"} spacing={2} p={1} justifyContent={"right"}>
                <div style={{display: 'flex'}}>
                    <div style={{ marginRight: 2, minWidth: 70, textAlign: 'center' }}>
                        <Button color="primary" variant="contained" onClick={() => {
                            navigate(`/places/${data._id}`)
                        }}>Edit</Button>
                    </div>
                    <div style={{ marginRight: 2, minWidth: 70, textAlign: 'center' }}>
                        <Button color="error" variant="contained" disabled={data._id.toString() === "66eee6b3d996a9edf2f856ae"} onClick={() => {
                            setOpenDialog({
                                open: true,
                                adminId: data._id
                            })
                        }}>Delete</Button>
                    </div>
                </div>
            </Grid>,
        })
    }


    
    return (
        <Grid>
            <Grid mb={1}>
                <Button variant="contained" onClick={() => {
                    navigate('/setting/admins/add-admin')
                }}>Add Admin</Button>
            </Grid>
            <Grid>
                <LBVTable
                    columns={[
                        { label: "Name" },
                        { label: "Username" },
                        { label: "Role" },
                        { label: "" },
                    ]}
                    loading={loading}
                    valueCustomator={valueCustomator}
                    totalData={0}
                    rows={admins}
                    page={1}
                    // pageChange={(e) => {
                    //     onChangePage({page: e})
                    // }}
                />
            </Grid>
            <Dialog open={openDialog.open} onClose={() => {
                setOpenDialog({
                    open: false,
                    adminId: null
                })
            }}>
                <Grid container p={2}>
                    <Grid item xs={12}>
                        <LBVLabel>
                            Are you sure you want to delete this admin ?
                        </LBVLabel>
                    </Grid>
                    <Grid item xs={12} textAlign={"right"} mt={1}>
                        <Button onClick={() => {
                            removeAdmin(openDialog.adminId)
                        }}>{"Delete"}</Button>
                        <Button onClick={() => { setOpenDialog({
                            open: false,
                            adminId: null
                        }) }} color="error">{"Cancel"}</Button>
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    )
}

export default Admins